import { graphql } from 'gatsby'
import StandartPageTemplate from './standart-page'

export default StandartPageTemplate

export const pageQuery = graphql`
    query NewsPostByID($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            htmlAst
            frontmatter {
                image
                title
                subtitle
            }
        }
    }
`